import styled from 'styled-components';
import { breakpoints } from 'styles';

export const InputFieldGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 16px;

  @media (max-width: ${breakpoints.l}px) {
    flex-direction: column;
    gap: 0;
  }
`;
