import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { formatDate } from 'utils/date';
import { getProductTreeItem } from 'utils/product';
import { getConnectivityStatusColors } from 'utils/connectivity';
import { Device, ProductTreeAttributes } from 'models';
import * as routes from 'router/Routes';

import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import DropdownMenu from 'components/UI/DropdownMenu';
import InfoItem from 'components/UI/InfoItem';
import IconButton from 'components/UI/IconButton';
import Icon, { IconType } from 'components/UI/Icon';
import Tag from 'components/UI/Tag';

import {
  ContentContainer,
  Image,
  Title,
  NameContainer,
  DetailGroup,
  Description,
  TitleContainer,
  EmptyImage
} from './styled';

type Props = {
  iprId: string;
  productTree: ProductTreeAttributes | null;
  device: Device | null;
  canEdit: boolean;
  openViewFactoryPinModal: () => void;
  onNavigateServicePlan: () => void;
};

const ProductNameCard: FC<Props> = ({
  iprId,
  productTree,
  device,
  canEdit,
  openViewFactoryPinModal,
  onNavigateServicePlan
}) => {
  const intl = useIntl();

  // Get product tree item
  const treeItem = useMemo(
    () => getProductTreeItem(productTree, iprId),
    [productTree, iprId]
  );

  // Details
  const details = useMemo(() => {
    // Connectivity status colors
    const statusColor = getConnectivityStatusColors(device?.attributes.status);
    const softwareUpdateColor = getConnectivityStatusColors(
      device?.attributes.softwareUpdate
    );

    // Detail list
    const detailList = [
      {
        id: 1,
        title: 'product_details.position',
        text: treeItem?.position ? <Tag>{treeItem.position}</Tag> : '-'
      },
      {
        id: 2,
        title: 'product_details.pnc',
        text: treeItem?.pnc
      },
      {
        id: 3,
        title: 'product_details.serial',
        text: treeItem?.serialNumber
      },
      {
        id: 4,
        title: 'product_details.hid',
        text: treeItem?.husqvarnaId
      },
      {
        id: 5,
        title: 'product_details.connection_status',
        text: device && (
          <Tag background={statusColor.background} color={statusColor.color}>
            {device.attributes.status?.toUpperCase()}
          </Tag>
        )
      },
      {
        id: 6,
        title: 'product_details.connection_last',
        text:
          device?.attributes.lastConnection &&
          formatDate(device.attributes.lastConnection)
      },
      {
        id: 7,
        title: 'product_details.connection_software_update',
        text: device && (
          <Tag
            background={softwareUpdateColor.background}
            color={softwareUpdateColor.color}
          >
            {device.attributes.softwareUpdate?.toUpperCase()}
          </Tag>
        )
      }
    ];
    return detailList.map(({ id, title, text }) => (
      <InfoItem
        key={id}
        title={intl.formatMessage({ id: title })}
        value={text || '—'}
      />
    ));
  }, [intl, treeItem, device]);

  // Context menu
  const contextMenu = useMemo(
    () => [
      ...(canEdit
        ? [
            {
              id: 1,
              text: intl.formatMessage({
                id: 'product_details.view_factory_pin'
              }),
              onClick: openViewFactoryPinModal
            }
          ]
        : []),
      {
        id: 2,
        text: intl.formatMessage({ id: 'product_details.view_service_plan' }),
        onClick: onNavigateServicePlan
      }
    ],
    [canEdit, intl, onNavigateServicePlan, openViewFactoryPinModal]
  );

  return (
    <Card>
      <CardHeader titleIntl="products.title" navUrl={routes.PRODUCTS}>
        {canEdit && (
          <DropdownMenu menu={contextMenu}>
            <IconButton>
              <Icon type={IconType.More} themeType="grey6" />
            </IconButton>
          </DropdownMenu>
        )}
      </CardHeader>
      <ContentContainer>
        <NameContainer>
          {treeItem?.imageUrl ? (
            <Image src={treeItem.imageUrl} />
          ) : (
            <EmptyImage>
              <Icon
                type={IconType.Image}
                size="extraExtraLarge"
                themeType="grey4"
              />
            </EmptyImage>
          )}
          <ContentContainer>
            <TitleContainer>
              <Title>{treeItem?.name}</Title>
              <Description>{treeItem?.type}</Description>
            </TitleContainer>
            <DetailGroup>{details}</DetailGroup>
          </ContentContainer>
        </NameContainer>
      </ContentContainer>
    </Card>
  );
};

export default ProductNameCard;
