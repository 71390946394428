import { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { setProductFilters } from 'store';
import { useAppDispatch } from 'hooks/redux';

import { ProductFilter, ProductFilterAttribute } from 'models';
import { createFilters, validateField } from 'utils/filtering';
import Button from 'components/UI/Button';
import InputField from 'components/UI/InputField';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { ButtonGrid } from 'styles';
import { InputFieldGroup } from './styled';

type Props = {
  filters: { active: boolean };
  onFilter: (productFilters: ProductFilter[]) => void;
};

const ProductSearch: FC<Props> = ({ filters, onFilter }) => {
  const dispatch = useAppDispatch();

  // Form
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm();

  // Toggle filters
  const toggleFilters = useCallback(() => {
    dispatch(setProductFilters(!filters.active));
  }, [dispatch, filters.active]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      const trimmedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [
          key,
          typeof value === 'string' ? value.trim() : value
        ])
      );
      const filters = createFilters<ProductFilter>(trimmedValues);
      if (filters.length) {
        onFilter(filters);
      }
      reset(trimmedValues);
    },
    [onFilter, reset]
  );

  // Watch fields
  const [pnc, localIdGardena] = watch([
    ProductFilterAttribute.PNC,
    ProductFilterAttribute.LocalIdGardena
  ]);

  // Reset
  const onReset = () =>
    reset({
      [ProductFilterAttribute.PNC]: '',
      [ProductFilterAttribute.LocalIdGardena]: '',
      [ProductFilterAttribute.SN]: ''
    });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputFieldGroup>
        <InputField
          type="text"
          description="products.input_pnc"
          register={register(ProductFilterAttribute.PNC, {
            required: validateField(!localIdGardena, 'input.required'),
            minLength: {
              value: 9,
              message: 'input.wrong_length'
            },
            maxLength: {
              value: 9,
              message: 'input.wrong_length'
            }
          })}
          error={errors.pnc}
          disabled={localIdGardena}
          ignoreNonAlphanumeric
        />
        <InputField
          type="text"
          description="products.filter_gardena_id"
          register={register(ProductFilterAttribute.LocalIdGardena, {
            required: validateField(!pnc, 'input.required')
          })}
          disabled={pnc}
          error={errors.localIdGardena}
        />
        <InputField
          type="text"
          description="products.input_serial"
          register={register(ProductFilterAttribute.SN, {
            required: validateField(true, 'input.required')
          })}
          error={errors.serialNumber}
          ignoreNonAlphanumeric
        />
      </InputFieldGroup>
      <ButtonGrid>
        <Button
          onClick={onReset}
          backgroundColor="transparent"
          color="primary"
          marginRight
        >
          <FormattedMessage id="search.clear" />
        </Button>
        <Button
          onClick={toggleFilters}
          backgroundColor="surface"
          color={filters.active ? 'primaryDark' : 'onSurface'}
          marginRight
        >
          <FormattedMessage id="search.advanced_btn" />
        </Button>
        <Button submit>
          <FormattedMessage id="search.btn" />
        </Button>
      </ButtonGrid>
    </form>
  );
};

export default ProductSearch;
