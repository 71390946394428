import { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { ProductFilter, ProductFilterAttribute } from 'models';
import { createFilters, validateField } from 'utils/filtering';

import Button from 'components/UI/Button';
import InputField from 'components/UI/InputField';

import { ButtonGrid, TwoColumnGrid } from 'styles';

type Props = {
  onFilter: (value: ProductFilter[]) => void;
};

const ProductFilters: FC<Props> = ({ onFilter }) => {
  // Form
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm();

  // Watch fields
  const [iprid, husqvarnaId] = watch([
    ProductFilterAttribute.IprId,
    ProductFilterAttribute.HID
  ]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      const trimmedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [
          key,
          typeof value === 'string' ? value.trim() : value
        ])
      );
      const filters = createFilters<ProductFilter>(trimmedValues);
      if (filters.length) {
        onFilter(filters);
      }
      reset(trimmedValues);
    },
    [onFilter, reset]
  );

  // Reset
  const onReset = () =>
    reset({
      [ProductFilterAttribute.IprId]: '',
      [ProductFilterAttribute.HID]: ''
    });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TwoColumnGrid>
        <InputField
          type="text"
          description="products.input_iprid"
          register={register(ProductFilterAttribute.IprId, {
            required: validateField(!husqvarnaId, 'input.required')
          })}
          disabled={husqvarnaId}
          error={errors.iprid}
        />
        <InputField
          type="text"
          description="products.input_husqvarnaId"
          register={register(ProductFilterAttribute.HID, {
            required: validateField(!iprid, 'input.required')
          })}
          disabled={iprid}
          error={errors.husqvarnaId}
          ignoreNonAlphanumeric
        />
      </TwoColumnGrid>
      <ButtonGrid>
        <Button
          onClick={onReset}
          backgroundColor="transparent"
          color="primary"
          marginRight
        >
          <FormattedMessage id="search.clear" />
        </Button>
        <Button submit>
          <FormattedMessage id="search.btn" />
        </Button>
      </ButtonGrid>
    </form>
  );
};

export default ProductFilters;
