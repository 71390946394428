import { FC, Fragment, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { fetchProductList, productListSelector } from 'store';

import { useAppDispatch, useAppSelector } from 'hooks/redux';

import Card from 'components/UI/Card';
import FoldableCard from 'components/UI/FoldableCard';
import Heading, { Type as HeadingType } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';

import ProductTable from 'components/products/ProductTable';
import ProductFilters from 'components/products/ProductFilters';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';
import ProductSearch from 'components/products/ProductSearch';
import { ProductFilter } from 'models';

const ProductSearchPage: FC = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading, filters } = useAppSelector(productListSelector);

  // Fetch contracts
  const fetchProducts = useCallback(
    (productFilters: ProductFilter[]) => {
      dispatch(fetchProductList(productFilters));
    },
    [dispatch]
  );

  // Render results
  const results = useMemo(() => {
    if (isLoading) {
      return <Loader center />;
    }
    if (!data) {
      return null;
    }
    if (!data.length) {
      return (
        <EmptyState icon={IconType.Product} padding>
          <FormattedMessage id="product_details.not_found" />
        </EmptyState>
      );
    }
    return (
      <Card>
        <Heading type={HeadingType.h3} uppercase>
          <FormattedMessage id="products.table_title" />
        </Heading>
        <ProductTable products={data} />
      </Card>
    );
  }, [data, isLoading]);

  return (
    <Fragment>
      <Heading>
        <FormattedMessage id="products.title" />
      </Heading>
      <Card>
        <Heading type={HeadingType.h3} uppercase>
          <FormattedMessage id="search.title" />
        </Heading>
        <ProductSearch filters={filters} onFilter={fetchProducts} />
      </Card>
      <FoldableCard open={filters.active}>
        <Heading type={HeadingType.h3} uppercase>
          <FormattedMessage id="search.advanced_title" />
        </Heading>
        <ProductFilters onFilter={fetchProducts} />
      </FoldableCard>
      {results}
    </Fragment>
  );
};

export default ProductSearchPage;
