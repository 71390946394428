import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import InfoItem from 'components/UI/InfoItem';
import Loader from 'components/UI/Loader';
import { DealerAttributes, WarrantyAttributes } from 'models';
import { DetailGroup } from './styled';
import { getDealerInfo } from 'utils/product';

type Props = {
  productRegistrationDealers?: DealerAttributes[];
  warrantyAttributes?: WarrantyAttributes | null;
  isLoading: boolean;
  descriptionIntl?: string;
};

const ProductDealerCard: FC<Props> = ({
  productRegistrationDealers = [],
  warrantyAttributes,
  isLoading,
  descriptionIntl
}) => {
  const intl = useIntl();

  const { dealer, dealerId } = useMemo(
    () => getDealerInfo(productRegistrationDealers, warrantyAttributes),
    [productRegistrationDealers, warrantyAttributes]
  );

  // Content
  const content = useMemo(() => {
    if (isLoading) {
      return <Loader center padding />;
    }

    if (!dealerId) {
      return (
        <p>{intl.formatMessage({ id: 'product_details.dealer_not_found' })}</p>
      );
    }

    const detailList = [
      {
        id: 1,
        title: 'product_details.dealer_id',
        text: dealerId
      },
      {
        id: 2,
        title: 'product_details.dealer_name',
        text: dealer?.name
      },
      {
        id: 3,
        title: 'product_details.dealer_address',
        text:
          dealer?.address &&
          [
            dealer.address.street,
            dealer.address.zipCity,
            dealer.address.country
          ]
            .filter(Boolean)
            .join(', ')
      }
    ];

    return (
      <DetailGroup>
        {detailList.map(({ id, title, text }) => (
          <InfoItem
            key={id}
            title={intl.formatMessage({ id: title })}
            value={text || '—'}
          />
        ))}
      </DetailGroup>
    );
  }, [isLoading, dealer, intl, dealerId]);

  return (
    <Card>
      <CardHeader
        titleIntl="product_details.dealer_title"
        descriptionIntl={descriptionIntl}
      />
      {content}
    </Card>
  );
};

export default ProductDealerCard;
