import { ChangeEvent, FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { regExp } from 'utils/constants';
import Icon, { IconType } from 'components/UI/Icon';
import Button from 'components/UI/Button';

import {
  Form,
  Input,
  InputField,
  ButtonGrid,
  InputIcon,
  InputButton
} from './styled';

type Props = {
  onSearch: (value: string) => void;
  onClear?: () => void;
  children?: ReactNode;
  buttons?: ReactNode;
  placeholder?: string;
  ignoreNonAlphanumeric?: boolean;
};

const SearchField: FC<Props> = ({
  onSearch,
  onClear,
  children,
  buttons,
  placeholder = '',
  ignoreNonAlphanumeric = false
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    resetField,
    formState: { isDirty }
  } = useForm();

  // Watch
  const search = watch('search');

  // Clear if empty
  useEffect(() => {
    if (onClear && isDirty && !search?.length) {
      onClear();
    }
  }, [onClear, isDirty, search]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      const value = values.search.trim();
      setValue('search', value);
      onSearch(value);
    },
    [onSearch, setValue]
  );

  // Reset
  const onReset = useCallback(
    () => resetField('search', { keepDirty: true }),
    [resetField]
  );

  // Clear button
  const clearButton = useMemo(() => {
    if (search?.length) {
      return (
        <InputButton type="button" onClick={onReset}>
          <Icon type={IconType.Close} themeType="grey6" size="small" />
        </InputButton>
      );
    }
  }, [onReset, search]);

  // Ignore non-alphanumeric characters
  const onInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (ignoreNonAlphanumeric) {
        event.target.value = event.target.value.replace(regExp.nonAlphanumeric, '');
      }
    },
    [ignoreNonAlphanumeric]
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputField>
        <InputIcon>
          <Icon type={IconType.Search} themeType="grey6" />
        </InputIcon>
        <Input
          {...register('search', { required: true })}
          placeholder={placeholder}
          onInput={onInput}
        />
        {clearButton}
      </InputField>
      {children}
      <ButtonGrid>
        {buttons}
        <Button submit>
          <FormattedMessage id="search.btn" />
        </Button>
      </ButtonGrid>
    </Form>
  );
};

export default SearchField;
